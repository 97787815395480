import {defineStore} from 'pinia'
import {mainAxios} from "@/main";


export const useApiSettingStore = defineStore({
    id: 'apiSettings',

    state: () => ({
        isMobile: false,
        apiSettings: null,
        sessionID: null,
        userID: null,
        error500: '',
        errorNetwork: false,
        overlayPopup: false,
        typePopup: '',
        overlayModal: false,
        hamburgerStatus: false,
        searchFilter: '',
        currentPageFilter: 1
    }),
    actions: {
        getApiSettings() {
            return new Promise((resolve, reject) => {
                mainAxios.options('api/')
                    .then(res => {
                        this.apiSettings = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters: {
        apiSettingsGetters: state => {
            return state.apiSettings
        },
        error500Getters: state => {
            return state.error500
        },
        errorNetworkGetters: state => {
            return state.errorNetwork
        },
        overlayModalGetters: state => {
            return state.overlayModal
        },
        overlayPopupGetters: state => {
            return state.overlayPopup
        },
        typePopupGetters: state => {
            return state.typePopup
        },
        hamburgerStatusGetters: state => {
            return state.hamburgerStatus
        },
        searchFilterGetters: state => {
            return state.searchFilter
        },
        currentPageFilterGetters: state => {
            return state.currentPageFilter
        },
        sessionIDGetters: state => {
            return state.sessionID
        },
        userIDGetters: state => {
            return state.userID
        },
        isMobileGetters: state => {
            return state.isMobile
        }
    }
})


